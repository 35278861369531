import { StateCreator } from "zustand";
import { Store } from "./types";
import { fetchConfig as fetchConfigAPI } from "@/lib/api/config";

export const createConfigSlice: StateCreator<
  Store,
  [],
  [],
  Pick<Store, "config" | "setConfig" | "fetchConfig">
> = (set) => ({
  config: null,
  setConfig: (config) => set({ config }),
  fetchConfig: async () => {
    console.log("Fetching config");
    const config = await fetchConfigAPI();
    set({ config });
  },
});
