import { useEffect } from "react";
import { useStore } from "@/state/useState";
import AppLayout from "@/components/AppLayout";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { ScrollArea } from "@/components/ui/scroll-area";

export default function IncidentsPage() {
  const fetchIncidents = useStore((state) => state.fetchIncidents);
  const incidents = useStore((state) => state.incidents);
  const fetchIncident = useStore((state) => state.fetchIncident);
  const currentIncident = useStore((state) => state.currentIncident);

  useEffect(() => {
    fetchIncidents();
  }, [fetchIncidents]);

  const fetchFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const formData = new FormData(e.target as HTMLFormElement);
    const incidentID = formData.get("incidentID") as string;
    fetchIncident(incidentID);
  };

  return (
    <AppLayout>
      {/* Left sidebar */}
      <Card className="flex flex-col h-full rounded-xl bg-card overflow-hidden">
        <CardHeader>
          <CardTitle className="text-xl">Incidents</CardTitle>
        </CardHeader>
        <CardContent>
          <ScrollArea className="h-[calc(100vh-200px)]">
            <ul>
              {incidents.map((incident) => (
                <li
                  key={incident.id}
                  className="flex justify-between items-center p-2 rounded hover:bg-accent"
                >
                  <span className="text-sm">
                    {incident.number}: {incident.briefDescription}
                  </span>
                  <Button
                    variant="secondary"
                    size="sm"
                    onClick={() => fetchIncident(incident.id)}
                  >
                    View
                  </Button>
                </li>
              ))}
            </ul>
          </ScrollArea>
        </CardContent>
      </Card>

      {/* Right content */}
      <Card className="col-span-2">
        <CardContent className="p-4">
          <form onSubmit={fetchFormSubmit} className="space-y-4 mb-4">
            <h2 className="text-lg font-semibold">Fetch Incident</h2>
            <div className="flex space-x-2">
              <Input type="text" placeholder="Incident ID" name="incidentID" />
              <Button type="submit">Fetch</Button>
            </div>
          </form>

          <div className="space-y-4">
            <div className="grid grid-cols-2 gap-4">
              <div>
                <label className="block text-sm font-medium mb-1">ID</label>
                <Input type="text" value={currentIncident?.id} disabled />
              </div>
              <div>
                <label className="block text-sm font-medium mb-1">Number</label>
                <Input type="text" value={currentIncident?.number} disabled />
              </div>
            </div>

            <div>
              <label className="block text-sm font-medium mb-1">Status</label>
              <Input
                type="text"
                value={currentIncident?.status?.name}
                disabled
              />
            </div>

            <div>
              <label className="block text-sm font-medium mb-1">
                Brief Description
              </label>
              <Textarea value={currentIncident?.briefDescription} disabled />
            </div>

            <div>
              <label className="block text-sm font-medium mb-1">Request</label>
              <Textarea value={currentIncident?.request} disabled />
            </div>
          </div>
        </CardContent>
      </Card>
    </AppLayout>
  );
}
