import { fetchWithAuth, urlBuilder } from "@/lib/api/utils";
import type { Category, Operator } from "@/lib/api/types";

export async function categorize(text: string): Promise<Category> {
  return fetchWithAuth(urlBuilder(`/categorize`), {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ text }),
  }).then((res) => res.json());
}

export async function assignOperator(
  text: string
): Promise<{ operator: Operator }> {
  return fetchWithAuth(urlBuilder(`/assign`), {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ text }),
  }).then((res) => res.json());
}
