import { useMsal } from "@azure/msal-react";
import { Button } from "@/components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";

const APPREG_SCOPE = import.meta.env.VITE_APPREG_SCOPE as string;

enum LoginType {
  MSAL_REDIRECT = "MSAL_REDIRECT",
}

export default function UnauthenticatedPage() {
  const { instance } = useMsal();

  const handleLogin = (loginType: LoginType) => {
    console.log(APPREG_SCOPE);
    if (loginType === LoginType.MSAL_REDIRECT) {
      instance
        .loginRedirect({
          scopes: [APPREG_SCOPE],
        })
        .catch((e) => {
          console.error(e);
        });
    }
  };

  return (
    <div className="grid h-screen w-full">
      <Card className="mx-auto my-auto max-w-sm">
        <CardHeader>
          <CardTitle className="text-2xl">Login</CardTitle>
          <CardDescription>
            Use one of the following methods to login.
          </CardDescription>
        </CardHeader>
        <CardContent>
          <div className="grid gap-4">
            <Button
              variant="outline"
              className="w-full"
              onClick={() => handleLogin(LoginType.MSAL_REDIRECT)}
            >
              Login with Microsoft
            </Button>
          </div>
        </CardContent>
      </Card>
    </div>
  );
}
