import { Document } from "@/lib/api/types";
import { useStore } from "@/state/useState";
import { Checkbox } from "@/components/ui/checkbox";
import { Label } from "@/components/ui/label";
import { ScrollArea } from "@/components/ui/scroll-area";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";

export default function SearchSidebar() {
  const documents = useStore((state) => state.documents);
  const setSelectedDocuments = useStore((state) => state.setSelectedDocuments);
  const selectedDocuments = useStore((state) => state.selectedDocuments);

  const handleCheckboxChange = (checked: boolean, document: Document) => {
    console.log({ document });
    if (document.status !== 2) // && document.status !== "indexed")
      return; // Prevent selection of unprocessed documents

    const newSelected = checked
      ? [...selectedDocuments, document]
      : selectedDocuments.filter((doc) => doc.id !== document.id);

    setSelectedDocuments(newSelected);
  };

  const isProcessed = (status: number) => status === 2;

  return (
    <Card className="md:flex flex-col hidden h-full rounded-xl bg-card overflow-hidden">
      <CardHeader className="flex flex-row items-center justify-between">
        <CardTitle className="text-xl">Documents</CardTitle>
      </CardHeader>
      <CardContent className="flex-grow overflow-y-auto p-0">
        <ScrollArea className="flex-grow px-1">
          <div className="space-y-2">
            {documents.map((document) => (
              <TooltipProvider key={document.id}>
                <Tooltip>
                  <TooltipTrigger asChild>
                    <div
                      className={`flex items-center space-x-2 p-2 rounded-md ${
                        isProcessed(document.status)
                          ? "hover:bg-accent"
                          : "opacity-50 cursor-not-allowed"
                      }`}
                    >
                      <Checkbox
                        id={`document-${document.id}`}
                        checked={selectedDocuments.some(
                          (doc) => doc.id === document.id
                        )}
                        onCheckedChange={(checked) =>
                          handleCheckboxChange(checked as boolean, document)
                        }
                        disabled={!isProcessed(document.status)}
                      />
                      <Label
                        htmlFor={`document-${document.id}`}
                        className={`text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70 flex-grow ${
                          !isProcessed(document.status)
                            ? "text-muted-foreground"
                            : ""
                        }`}
                      >
                        {document.origin}
                        {!isProcessed(document.status) && " (Processing)"}
                      </Label>
                    </div>
                  </TooltipTrigger>
                  {!isProcessed(document.status) && (
                    <TooltipContent>
                      <p>
                        This document is still being processed and cannot be
                        selected yet.
                      </p>
                    </TooltipContent>
                  )}
                </Tooltip>
              </TooltipProvider>
            ))}
          </div>
        </ScrollArea>
      </CardContent>
    </Card>
  );
}
