import { NavLink } from "react-router-dom";
import { useStore } from "@/state/useState";
import { useMsal } from "@azure/msal-react";
import {
  Brain,
  MessageCircle,
  Search,
  File,
  // SquareKanban, # Declared but never read.
  LogOut,
  // CircleAlert, # Declared but never read.
  // CloudUpload,
} from "lucide-react";
import TicketBuddyIcon from "./assets/TicketBuddyIcon";
import { Button } from "@/components/ui/button";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@/components/ui/tooltip";

export default function Sidebar() {
  const config = useStore((state) => state.config);

  const { instance } = useMsal();

  const handleLogout = () => {
    instance
      .logoutRedirect({
        postLogoutRedirectUri: "/",
      })
      .catch((e) => {
        console.error(e);
      });
  };

  const navItems = [
    { icon: MessageCircle, label: "Chat", path: "/chat" },
    { icon: Search, label: "Search", path: "/search" },
    { icon: File, label: "Documents", path: "/files" },
    // { icon: SquareKanban, label: "Knowledge Items", path: "/knowledgeitem" },
    // { icon: CircleAlert, label: "Incidents", path: "/incidents" },
    // { icon: CloudUpload, label: "SharePoint", path: "/sharepoint" },
  ];

  return (
    <aside className="inset-y fixed left-0 z-20 flex h-full flex-col border-r">
      <div className="border-b p-2">
        <NavLink to="/" end>
          {({ isActive }) => (
            <Button
              variant={isActive ? "default" : "outline"}
              size="icon"
              aria-label="Home"
            >
              {config?.ticketbuddy_logo ? (
                <TicketBuddyIcon />
              ) : (
                <Brain className="size-5" />
              )}
            </Button>
          )}
        </NavLink>
      </div>
      <nav className="grid gap-1 p-2">
        {navItems.map(({ icon: Icon, label, path }) => (
          <Tooltip key={label}>
            <TooltipTrigger asChild>
              <NavLink to={path} end>
                {({ isActive }) => (
                  <Button
                    variant="ghost"
                    size="icon"
                    className={`rounded-lg ${isActive ? "bg-muted" : ""}`}
                    aria-label={label}
                  >
                    <Icon className="size-5" />
                  </Button>
                )}
              </NavLink>
            </TooltipTrigger>
            <TooltipContent side="right" sideOffset={5}>
              {label}
            </TooltipContent>
          </Tooltip>
        ))}
      </nav>
      <nav className="mt-auto grid gap-1 p-2">
        <Tooltip>
          <TooltipTrigger asChild>
            <Button
              variant="ghost"
              size="icon"
              className="mt-auto rounded-lg"
              aria-label="Logout"
              onClick={handleLogout}
            >
              <LogOut className="size-5" />
            </Button>
          </TooltipTrigger>
          <TooltipContent side="right" sideOffset={5}>
            Logout
          </TooltipContent>
        </Tooltip>
      </nav>
    </aside>
  );
}
