const TicketBuddyLogo = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="200 265 448 68"
    className="w-full h-8"
  >
    <g>
      <path
        d="M235.2 300.2c-4.7 0-8.4 3.7-8.4 8.5s3.8 8.5 8.4 8.5 8.5-3.7 8.5-8.5-3.6-8.5-8.5-8.5Z"
        fill="#e65038"
      />
      <path
        d="M241.4 294.1c0 4.7 3.7 8.4 8.5 8.4 4.7 0 8.5-3.8 8.5-8.4 0-4.8-3.7-8.5-8.5-8.5s-8.5 3.6-8.5 8.5Z"
        fill="#e65038"
      />
      <path
        d="M235.2 271c-4.7 0-8.4 3.7-8.4 8.5s3.8 8.5 8.4 8.5 8.5-3.7 8.5-8.5-3.6-8.5-8.5-8.5Z"
        fill="#149ad0"
      />
      <path
        d="M212.2 294.1c0 4.7 3.7 8.4 8.5 8.4 4.7 0 8.5-3.8 8.5-8.4s-3.7-8.5-8.5-8.5-8.5 3.6-8.5 8.5Z"
        fill="#149ad0"
      />
      <path
        d="M275.2 281.6h-12v-9.9h31.2v9.9h-8.8v34.8h-10.3v-34.8Zm23.7.3h9.9v34.5h-9.9v-34.5Zm5-15.1c-3.1 0-5.6 2.5-5.6 5.6s2.5 5.6 5.6 5.6 5.6-2.5 5.6-5.6-2.5-5.6-5.6-5.6Zm7.3 32.4c0-10.3 7.8-18 18.1-18s15.2 4.4 16.8 13l-9.6 1.6c-.8-3.8-4.3-5.2-7.2-5.2-4.6 0-8.2 3.7-8.2 8.6s3.6 8.4 8.2 8.4 6.3-1.5 7.3-5.2l9.9.8c-1.5 9.5-9.8 13.8-17.2 13.8s-18.1-7.7-18.1-17.9Zm37.6-30.2h9.8v27.1l12.8-14.2h12.5L371.3 295l13.7 21.4h-11.7l-8.9-14.2-5.7 6v8.2h-9.8v-47.3Zm32.9 30.2c0-10.5 7.7-18 17.5-18s17 7.1 17 16.4-.1 2.9-.3 4h-24.5c.9 4.4 3.8 6.9 8.3 6.9s6-1.7 6.5-4.3l10.3.9c-1.7 7.4-7.3 12-16.4 12s-18.4-7.3-18.4-17.9Zm24.1-4.8c-.1-2.9-2.9-5-6.7-5s-6.2 1.9-7.3 5h14Zm16.2 9.2v-13.3h-6.2v-8.4h6.2v-8.7h9.9v8.7h8v8.4h-8v12.4c0 4.1 2.2 5.4 4.9 5.4s2.2-.3 3-.6l.5 8.2c-1.1.6-2.9 1.1-5.4 1.1-8.1 0-12.9-5-12.9-13.3Zm21.9-31.9h17.7c8 0 14.2 4.6 14.2 11.5s-2.7 8.2-6.2 9.5c4.5 1.4 7.5 5.5 7.5 10.5 0 8.1-6.5 13.1-14.2 13.1h-19v-44.7Zm16.9 17.4c2.6 0 4.5-1.6 4.5-4s-1.8-4-4.3-4h-6.8v8h6.6Zm1 17.6c2.6 0 4.7-1.9 4.7-4.5s-2.1-4.5-4.6-4.5h-7.7v9h7.5Zm18.2-5.8v-19h9.8v18.2c0 4.4 2.7 7.5 6.8 7.5s6.8-2.9 6.9-8.1v-17.6h9.8v34.5h-8.7v-5.6c-2.6 4.1-6.4 6.3-11.3 6.3-8.2 0-13.3-6.1-13.3-16.2Zm36-1.7c0-10.1 7.1-17.9 16.6-17.9s7.7 1.3 10.3 3.7v-15.9h9.8v37.4c0 4.3.3 7.8 1 10h-9.8v-4.2c-2.6 3.2-6.4 4.9-11.4 4.9-9.4 0-16.6-7.9-16.6-17.9Zm18.4 8.5c4.8 0 8.5-3.7 8.5-8.6s-3.6-8.5-8.5-8.5-8.4 3.8-8.4 8.5 3.8 8.6 8.4 8.6Zm21-8.5c0-10.1 7.1-17.9 16.6-17.9s7.7 1.3 10.3 3.7v-15.9h9.8v37.4c0 4.3.3 7.8 1 10h-9.8v-4.2c-2.6 3.2-6.4 4.9-11.4 4.9-9.4 0-16.6-7.9-16.6-17.9Zm18.3 8.5c4.8 0 8.5-3.7 8.5-8.6s-3.6-8.5-8.5-8.5-8.4 3.8-8.4 8.5 3.8 8.6 8.4 8.6Zm19.3 19.1 3-9.4c1.5.8 3.4 1.5 5 1.5 2.5 0 3.7-1.5 4.7-3.7l.6-1.5-13.2-31.7h10.6l7.7 20.3 7.7-20.3h10.6l-15.2 36.4c-2.4 5.7-6.1 10.1-13.8 10.1s-5.9-.8-7.7-1.7Z"
        fill="#000000"
      />
    </g>
  </svg>
);

export default TicketBuddyLogo;
