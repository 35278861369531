import { useStore } from "@/state/useState";
import { Settings } from "lucide-react";
import { Button } from "@/components/ui/button";
import {
  Drawer,
  DrawerContent,
  DrawerDescription,
  DrawerHeader,
  DrawerTitle,
  DrawerTrigger,
} from "@/components/ui/drawer";
import TicketBuddyLogo from "@/components/assets/TicketBuddyLogo";

export default function Header() {
  const config = useStore((state) => state.config);

  return (
    <header className="sticky top-0 z-10 flex h-[57px] items-center gap-1 border-b bg-background px-4">
      {config?.ticketbuddy_logo ? (
        <div>
          <TicketBuddyLogo />
        </div>
      ) : (
        <h1 className="text-xl font-semibold">{config?.ai.name}</h1>
      )}
      <Drawer>
        <DrawerTrigger asChild>
          <Button variant="ghost" size="icon" className="md:hidden">
            <Settings className="size-4" />
            <span className="sr-only">Settings</span>
          </Button>
        </DrawerTrigger>
        <DrawerContent className="max-h-[80vh]">
          <DrawerHeader>
            <DrawerTitle>Configuration</DrawerTitle>
            <DrawerDescription>
              Configure the settings for the model and messages.
            </DrawerDescription>
          </DrawerHeader>
        </DrawerContent>
      </Drawer>
    </header>
  );
}
