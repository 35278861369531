import { fetchWithAuth, urlBuilder } from "@/lib/api/utils";
import { HistoryItem, SessionItem } from "@/lib/api/types";

export async function fetchSessionList(): Promise<HistoryItem[]> {
  return fetchWithAuth(urlBuilder("/session")).then((res) => res.json());
}

export async function deleteSessionById(conversationId: string): Promise<void> {
  await fetchWithAuth(urlBuilder(`/session/${conversationId}`), {
    method: "DELETE",
  });
}

export async function getSessionById(
  conversationId: string
): Promise<SessionItem[]> {
  return fetchWithAuth(urlBuilder(`/session/${conversationId}`)).then((res) =>
    res.json()
  );
}
