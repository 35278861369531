import React, { useEffect } from "react";
import { useStore } from "@/state/useState";
import AppLayout from "@/components/AppLayout";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { ScrollArea } from "@/components/ui/scroll-area";

export default function KnowledgeItemPage() {
  const fetchKnowledgeItem = useStore((state) => state.fetchKnowledgeItem);
  const currentKnowledgeItem = useStore((state) => state.currentKnowledgeItem);

  const fetchKnowledgeItems = useStore((state) => state.fetchKnowledgeItems);
  const allKnowledgeItems = useStore((state) => state.knowledgeItems);

  useEffect(() => {
    fetchKnowledgeItems(0, 100, "description");
  }, [fetchKnowledgeItems]);

  const fetchFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const formData = new FormData(e.target as HTMLFormElement);
    const knowledgeItemID = formData.get("knowledgeItemID") as string;
    fetchKnowledgeItem(
      knowledgeItemID,
      "language,content,description,commentsForOperators,keywords"
    );
  };

  return (
    <AppLayout>
      {/* Left sidebar */}
      <Card className="flex flex-col h-full rounded-xl bg-card overflow-hidden">
        <CardHeader>
          <CardTitle className="text-xl">Knowledge Items</CardTitle>
        </CardHeader>
        <CardContent>
          <ScrollArea className="h-[calc(100vh-200px)]">
            <ul>
              {allKnowledgeItems.map((item) => (
                <li
                  key={item.id}
                  className="flex justify-between items-center p-2 rounded hover:bg-accent"
                >
                  <span className="text-sm">
                    {item.number}:{" "}
                    <span
                      dangerouslySetInnerHTML={{
                        __html: item.translation.content.description ?? "",
                      }}
                    />
                  </span>
                  <Button
                    variant="secondary"
                    size="sm"
                    onClick={() => {
                      fetchKnowledgeItem(
                        item.id!,
                        "language,content,description,commentsForOperators,keywords"
                      );
                    }}
                  >
                    View
                  </Button>
                </li>
              ))}
            </ul>
          </ScrollArea>
        </CardContent>
      </Card>

      {/* Right content */}
      <Card className="col-span-2">
        <CardContent className="p-4">
          <form onSubmit={fetchFormSubmit} className="space-y-4 mb-4">
            <h2 className="text-lg font-semibold">Fetch Knowledge Item</h2>
            <div className="flex space-x-2">
              <Input
                type="text"
                placeholder="Knowledge Item ID"
                name="knowledgeItemID"
              />
              <Button type="submit">Fetch</Button>
            </div>
          </form>

          <div className="space-y-4">
            <div className="grid grid-cols-2 gap-4">
              <div>
                <label className="block text-sm font-medium mb-1">ID</label>
                <Input type="text" value={currentKnowledgeItem?.id} disabled />
              </div>
              <div>
                <label className="block text-sm font-medium mb-1">Number</label>
                <Input
                  type="text"
                  value={currentKnowledgeItem?.number}
                  disabled
                />
              </div>
            </div>

            <div>
              <label className="block text-sm font-medium mb-1">Language</label>
              <Input
                type="text"
                value={currentKnowledgeItem?.translation.language}
                disabled
              />
            </div>

            <div>
              <label className="block text-sm font-medium mb-1">
                Description
              </label>
              <Textarea
                value={currentKnowledgeItem?.translation.content.description}
                disabled
              />
            </div>

            <div>
              <label className="block text-sm font-medium mb-1">Content</label>
              <Textarea
                value={currentKnowledgeItem?.translation.content.content}
                disabled
              />
            </div>

            <div>
              <label className="block text-sm font-medium mb-1">
                Comments for Operators
              </label>
              <Textarea
                value={
                  currentKnowledgeItem?.translation.content.commentsForOperators
                }
                disabled
              />
            </div>

            <div>
              <label className="block text-sm font-medium mb-1">Keywords</label>
              <Textarea
                value={currentKnowledgeItem?.translation.content.keywords}
                disabled
              />
            </div>
          </div>
        </CardContent>
      </Card>
    </AppLayout>
  );
}
