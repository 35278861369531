import { acquireAccessToken, msalInstance } from "@/lib/auth";
import type { Message } from "@/lib/api/types";
import { toast } from "@/hooks/use-toast";

const baseUrl = import.meta.env.VITE_API_URL;

export function urlBuilder(path: string) {
  return new URL(path.replace(/^\//, ""), baseUrl);
}

export function buildMessage({
  message: messageString,
  role = "user",
  image = null,
}: {
  message: string;
  role: "user";
  image?: string | null;
}): Message {
  return {
    created_at: new Date().toISOString(),
    id: self.crypto.randomUUID(),
    role,
    content: {
      text: messageString,
      image,
    },
  };
}

export async function fetchWithAuth(
  input: RequestInfo | URL,
  init?: RequestInit
): Promise<Response> {
  try {
    const token = await acquireAccessToken(msalInstance);
    const headers = new Headers(init?.headers);
    headers.set("Authorization", `Bearer ${token}`);

    const response = await fetch(input, {
      ...init,
      headers,
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    return response;
  } catch (error) {
    console.error("Fetch error:", error);
    toast({
      title: "Error",
      description: "An error occurred while fetching data. Please try again.",
      variant: "destructive",
    });
    throw error; // Re-throw the error so it can be handled by the calling function if needed
  }
}

