import { useStore } from "@/state/useState";
import { useEffect } from "react";

import SearchForm from "@/components/searchpage/SearchForm";
import SearchResults from "@/components/searchpage/SearchResults";
import SearchSidebar from "@/components/searchpage/SearchSidebar";

import AppLayout from "@/components/AppLayout";

export default function SearchPage() {
  useEffect(() => {
    useStore.getState().fetchConfig();
    useStore.getState().loadDocuments();
  }, []);

  return (
    <AppLayout>
      <SearchSidebar />
      <div className="flex flex-col h-full overflow-hidden lg:col-span-2 space-y-2">
        <SearchForm />
        <SearchResults />
      </div>
    </AppLayout>
  );
}
