import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { MsalProvider } from "@azure/msal-react";
import { msalInstance } from "@/lib/auth";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import { useEffect } from "react";
import { useStore } from "@/state/useState";

import ChatPage from "@/pages/ChatPage";
import SearchPage from "@/pages/SearchPage";
import KnowledgeItemPage from "@/pages/KnowledgeItemPage";
import UnauthenticatedPage from "@/pages/UnauthenticatedPage";
import FilesPage from "@/pages/FilesPage";
import IncidentsPage from "@/pages/IncidentsPage";
import RouterWrapper from "@/lib/RouterWrapper";
import { TooltipProvider } from "@/components/ui/tooltip";
import { Toaster } from "@/components/ui/toaster";

function AuthenticatedApp() {
  const startDocumentPolling = useStore((state) => state.startDocumentPolling);
  const stopDocumentPolling = useStore((state) => state.stopDocumentPolling);

  useEffect(() => {
    startDocumentPolling();
    return () => stopDocumentPolling();
  }, [startDocumentPolling, stopDocumentPolling]);

  return (
    <BrowserRouter>
      <RouterWrapper>
        <Routes>
          <Route path="/chat" element={<ChatPage />} />
          <Route path="/search" element={<SearchPage />} />
          <Route path="/files" element={<FilesPage />} />
          <Route path="/knowledgeitem" element={<KnowledgeItemPage />} />
          <Route path="/incidents" element={<IncidentsPage />} />
          <Route path="*" element={<Navigate to="/chat" replace />} />
        </Routes>
      </RouterWrapper>
    </BrowserRouter>
  );
}

export default function App() {
  return (
    <TooltipProvider>
      <MsalProvider instance={msalInstance}>
        <AuthenticatedTemplate>
          <AuthenticatedApp />
        </AuthenticatedTemplate>

        <UnauthenticatedTemplate>
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<UnauthenticatedPage />} />
              <Route path="*" element={<Navigate to="/" replace />} />
            </Routes>
          </BrowserRouter>
        </UnauthenticatedTemplate>
      </MsalProvider>
      <Toaster />
    </TooltipProvider>
  );
}
