import { useEffect } from "react";
import { useLocation } from "react-router-dom";

import { useStore } from "@/state/useState";

export default function RouterWrapper({
  children,
}: {
  children: React.ReactNode;
}) {
  const location = useLocation();
  const fetchConfig = useStore((state) => state.fetchConfig);

  useEffect(() => {
    fetchConfig();
  }, [location, fetchConfig]);

  return children;
}
