import { fetchWithAuth, urlBuilder } from "@/lib/api/utils";
import { SearchResult } from "@/lib/api/types";

export async function search(
  query: string,
  documentIds: string[]
): Promise<SearchResult[]> {
  const url = urlBuilder("/search");
  url.searchParams.append("text", query);
  documentIds.forEach((id) => url.searchParams.append("document_ids", id));

  return fetchWithAuth(url).then((res) => res.json());
}
