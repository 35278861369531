import { fetchWithAuth, urlBuilder } from "@/lib/api/utils";

// TODO: TYPES
export async function submitFeedback(
  messageId: string,
  feedback: string
): Promise<void> {
  await fetchWithAuth(urlBuilder(`/feedback/${messageId}`), {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ feedback }),
  });
}
