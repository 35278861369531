import { fetchWithAuth, urlBuilder } from "@/lib/api/utils";
import type { Message } from "@/lib/api/types";

export async function generateChatResponse(
  messages: Message[],
  sessionId: string | null,
  onMessage: (message: string) => void
): Promise<void> {
  const response = await fetchWithAuth(urlBuilder("/generate"), {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      session_id: sessionId ?? null,
      messages: messages,
    }),
  });

  if (!response.ok) {
    throw new Error("Network response was not ok");
  }

  if (!response.body) {
    throw new Error(
      "ReadableStream not supported by the browser or no response body"
    );
  }

  const reader = response.body.getReader();
  const decoder = new TextDecoder();
  let done = false;
  let buffer = "";

  while (!done) {
    const { value, done: readerDone } = await reader.read();
    done = readerDone;
    if (value) {
      const chunk = decoder.decode(value, { stream: true });
      buffer += chunk;

      let boundary = buffer.indexOf("\n");
      while (boundary !== -1) {
        const completeChunk = buffer.slice(0, boundary);
        buffer = buffer.slice(boundary + 1);
        onMessage(completeChunk);
        boundary = buffer.indexOf("\n");
      }
    }
  }

  if (buffer.length > 0) {
    onMessage(buffer); // Handle any remaining text in the buffer
  }

  // onMessage(""); // Optional: signal the end of the stream
}
