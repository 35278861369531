import { StateCreator } from "zustand";
import { Store } from "./types";
import packageJson from "@/../package.json";

export const createPackageSlice: StateCreator<
  Store,
  [],
  [],
  Pick<Store, "package">
> = () => ({
  package: {
    name: packageJson.name,
    version: packageJson.version,
  },
});
