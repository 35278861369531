import { StateCreator } from "zustand";
import type { Store } from "./types";
import type { Document } from "@/lib/api/types";
import { search as searchAPI } from "@/lib/api/search";

export const createSearchSlice: StateCreator<
  Store,
  [],
  [],
  Pick<
    Store,
    "searchResults" | "search" | "selectedDocuments" | "setSelectedDocuments"
  >
> = (set) => ({
  searchResults: [],
  search: async (query: string, documentIds: string[]) => {
    console.log("Searching for query: ", query);
    console.log("Selected documents: ", documentIds);
    const results = await searchAPI(query, documentIds);
    set({ searchResults: results });
  },
  selectedDocuments: [],
  setSelectedDocuments: (documents: Document[]) => {
    set({ selectedDocuments: documents });
  },
});
