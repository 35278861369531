import { StateCreator } from "zustand";
import { Store } from "./types";
import { generateChatResponse as generateChatResponseAPI } from "@/lib/api/chat";
import type { ChatResponse } from "@/lib/api/types";

export const createChatSlice: StateCreator<
  Store,
  [],
  [],
  Pick<Store, "messages" | "sessionId" | "clearChat" | "submitMessage">
> = (set, get) => ({
  messages: [],
  sessionId: null,
  clearChat: () => {
    console.log("Clearing chat");
    set({ messages: [], sessionId: null });
  },
  submitMessage: (reqMessage) => {
    console.log("Submitting message: ", reqMessage);
    set((state) => ({ messages: [...state.messages, reqMessage] }));

    const allMessages = get().messages.map((message) => ({
      content: {
        ...message.content,
        text: message.content.text,
        image: message.content.image ?? null,
      },
      role: message.role,
      created_at: message.created_at,
      id: message.id,
    }));

    const handleStreamMessage = (chunk: string) => {
      const parsedChunk = JSON.parse(chunk) as ChatResponse;

      if (parsedChunk.history_metadata.session_id && !get().sessionId) {
        set({ sessionId: parsedChunk.history_metadata.session_id });
        get().fetchSessionList();
      }

      const resMessage = parsedChunk.choices[0].messages[0];

      if (!get().messages.find((x) => x.id === resMessage.id)) {
        set((state) => ({
          messages: [...state.messages, ...parsedChunk.choices[0].messages],
        }));
      } else {
        set((state) => ({
          messages: state.messages.map((current) =>
            current.id === resMessage.id
              ? {
                  ...current,
                  content: {
                    text: current.content.text + resMessage.content.text,
                    image: resMessage.content.image ?? null,
                    citations: [
                      ...(current.content.citations ?? []),
                      ...(resMessage.citations ?? []),
                    ],
                  },
                }
              : current
          ),
        }));
      }
    };

    generateChatResponseAPI(allMessages, get().sessionId, handleStreamMessage);
  },
});
