const TicketBuddyIcon = ({ width = "28", height = "28" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 100 100"
      aria-hidden="true"
    >
      <circle cx="50" cy="20" r="15" fill="#149AD0" />
      <circle cx="50" cy="80" r="15" fill="#E65038" />
      <circle cx="20" cy="50" r="15" fill="#149AD0" />
      <circle cx="80" cy="50" r="15" fill="#E65038" />
    </svg>
  );
};

export default TicketBuddyIcon;
