import { useState } from "react";
import { useStore } from "@/state/useState";
import { Card, CardContent } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { ScrollArea } from "@/components/ui/scroll-area";

function ReadMoreText({
  text,
  maxLength = 300,
}: {
  text: string;
  maxLength?: number;
}) {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleIsExpanded = () => setIsExpanded(!isExpanded);

  if (text.length <= maxLength) {
    return <p className="text-sm">{text}</p>;
  }

  return (
    <div className="space-y-2">
      <p className="text-sm">
        {isExpanded ? text : `${text.substring(0, maxLength)}...`}
      </p>
      <Button
        variant="link"
        className="p-0 h-auto text-sm"
        onClick={toggleIsExpanded}
      >
        {isExpanded ? "Show less" : "Read more"}
      </Button>
    </div>
  );
}

export default function SearchResults() {
  const searchResults = useStore((state) => state.searchResults);

  return (
    <ScrollArea className="h-[calc(100vh-300px)]">
      {searchResults.map((result) => (
        <Card key={result.id} className="mb-4">
          <CardContent className="pt-6">
            <ReadMoreText text={result.content} />
            <Button
              variant="link"
              className="p-0 h-auto text-xs text-muted-foreground hover:underline"
            >
              {result.document_source}
            </Button>
          </CardContent>
        </Card>
      ))}
    </ScrollArea>
  );
}
