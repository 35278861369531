import { fetchWithAuth, urlBuilder } from "@/lib/api/utils";
import {
  KnowledgeItem,
  CreateKnowledgeItemParams,
  UpdateKnowledgeItemParams,
  KnowledgeItemTranslation,
  KnowledgeItemContent,
  KnowledgeItemStatus,
  Attachment,
  Branch,
  Incident,
} from "@/lib/api/types";

export async function fetchKnowledgeItems(
  start: number = 0,
  pageSize: number = 100,
  fields?: string,
  query?: string,
  language?: string
): Promise<{
  item: KnowledgeItem[];
  prev: string | null;
  next: string | null;
}> {
  const params = new URLSearchParams({
    start: start.toString(),
    page_size: pageSize.toString(),
    ...(fields && { fields }),
    ...(query && { query }),
    ...(language && { language }),
  });
  return fetchWithAuth(urlBuilder(`/knowledgeitems?${params}`)).then((res) =>
    res.json()
  );
}

export async function fetchKnowledgeItem(
  itemId: string,
  fields?: string,
  language?: string
): Promise<KnowledgeItem> {
  const params = new URLSearchParams({
    ...(fields && { fields }),
    ...(language && { language }),
  });
  return fetchWithAuth(urlBuilder(`/knowledgeitem/${itemId}?${params}`)).then(
    (res) => res.json()
  );
}

export async function createKnowledgeItem(
  knowledgeItem: CreateKnowledgeItemParams
): Promise<KnowledgeItem> {
  return fetchWithAuth(urlBuilder("/knowledgeitem"), {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(knowledgeItem),
  }).then((res) => res.json());
}

export async function updateKnowledgeItem(
  itemId: string,
  knowledgeItem: UpdateKnowledgeItemParams
): Promise<void> {
  return fetchWithAuth(urlBuilder(`/knowledgeitem/${itemId}`), {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(knowledgeItem),
  }).then((res) => res.json());
}

export async function archiveKnowledgeItem(itemId: string): Promise<void> {
  return fetchWithAuth(urlBuilder(`/knowledgeitem/${itemId}/archive`), {
    method: "POST",
  }).then((res) => res.json());
}

export async function unarchiveKnowledgeItem(itemId: string): Promise<void> {
  return fetchWithAuth(urlBuilder(`/knowledgeitem/${itemId}/unarchive`), {
    method: "POST",
  }).then((res) => res.json());
}

export async function createTranslation(
  itemId: string,
  translation: KnowledgeItemTranslation
): Promise<void> {
  return fetchWithAuth(urlBuilder(`/knowledgeitem/${itemId}/translations`), {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(translation),
  }).then((res) => res.json());
}

export async function updateTranslation(
  itemId: string,
  language: string,
  content: KnowledgeItemContent
): Promise<void> {
  return fetchWithAuth(
    urlBuilder(`/knowledgeitem/${itemId}/translations/${language}`),
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(content),
    }
  ).then((res) => res.json());
}

export async function deleteTranslation(
  itemId: string,
  language: string
): Promise<void> {
  return fetchWithAuth(
    urlBuilder(`/knowledgeitem/${itemId}/translations/${language}`),
    {
      method: "DELETE",
    }
  ).then((res) => res.json());
}

export async function getLinkedBranches(itemId: string): Promise<Branch[]> {
  return fetchWithAuth(urlBuilder(`/knowledgeitem/${itemId}/branches`)).then(
    (res) => res.json()
  );
}

export async function linkBranch(
  itemId: string,
  branchId: string
): Promise<void> {
  return fetchWithAuth(urlBuilder(`/knowledgeitem/${itemId}/branches/link`), {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ branch_id: branchId }),
  }).then((res) => res.json());
}

export async function unlinkBranch(
  itemId: string,
  branchId: string
): Promise<void> {
  return fetchWithAuth(urlBuilder(`/knowledgeitem/${itemId}/branches/unlink`), {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ branch_id: branchId }),
  }).then((res) => res.json());
}

export async function listImages(itemId: string): Promise<string[]> {
  return fetchWithAuth(urlBuilder(`/knowledgeitem/${itemId}/images`)).then(
    (res) => res.json()
  );
}

export async function uploadImage(itemId: string, file: File): Promise<void> {
  const formData = new FormData();
  formData.append("file", file);
  return fetchWithAuth(urlBuilder(`/knowledgeitem/${itemId}/images`), {
    method: "POST",
    body: formData,
  }).then((res) => res.json());
}

export async function downloadImage(
  itemId: string,
  imageName: string
): Promise<Blob> {
  return fetchWithAuth(
    urlBuilder(`/knowledgeitem/${itemId}/images/${imageName}/download`)
  ).then((res) => res.blob());
}

export async function deleteImage(
  itemId: string,
  imageName: string
): Promise<void> {
  return fetchWithAuth(
    urlBuilder(`/knowledgeitem/${itemId}/images/${imageName}`),
    {
      method: "DELETE",
    }
  ).then((res) => res.json());
}

export async function listAttachments(itemId: string): Promise<Attachment[]> {
  return fetchWithAuth(urlBuilder(`/knowledgeitem/${itemId}/attachments`)).then(
    (res) => res.json()
  );
}

export async function uploadAttachment(
  itemId: string,
  file: File,
  description?: string
): Promise<void> {
  const formData = new FormData();
  formData.append("file", file);
  if (description) {
    formData.append("description", description);
  }
  return fetchWithAuth(urlBuilder(`/knowledgeitem/${itemId}/attachments`), {
    method: "POST",
    body: formData,
  }).then((res) => res.json());
}

export async function downloadAttachment(
  itemId: string,
  attachmentId: string
): Promise<Blob> {
  return fetchWithAuth(
    urlBuilder(`/knowledgeitem/${itemId}/attachments/${attachmentId}/download`)
  ).then((res) => res.blob());
}

export async function deleteAttachment(
  itemId: string,
  attachmentId: string
): Promise<void> {
  return fetchWithAuth(
    urlBuilder(`/knowledgeitem/${itemId}/attachments/${attachmentId}`),
    {
      method: "DELETE",
    }
  ).then((res) => res.json());
}

export async function getKnowledgeItemStatuses(
  archived?: boolean
): Promise<KnowledgeItemStatus[]> {
  const params = new URLSearchParams(
    archived !== undefined ? { archived: archived.toString() } : {}
  );
  return fetchWithAuth(urlBuilder(`/knowledgeitemstatuses?${params}`)).then(
    (res) => res.json()
  );
}

export async function createKnowledgeItemStatus(
  name: string
): Promise<KnowledgeItemStatus> {
  return fetchWithAuth(urlBuilder("/knowledgeitemstatuses"), {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ name }),
  }).then((res) => res.json());
}

export async function updateKnowledgeItemStatus(
  statusId: string,
  name: string
): Promise<KnowledgeItemStatus> {
  return fetchWithAuth(urlBuilder(`/knowledgeitemstatuses/${statusId}`), {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ name }),
  }).then((res) => res.json());
}

export async function archiveKnowledgeItemStatus(
  statusId: string
): Promise<KnowledgeItemStatus> {
  return fetchWithAuth(
    urlBuilder(`/knowledgeitemstatuses/${statusId}/archive`),
    {
      method: "POST",
    }
  ).then((res) => res.json());
}

export async function unarchiveKnowledgeItemStatus(
  statusId: string
): Promise<KnowledgeItemStatus> {
  return fetchWithAuth(
    urlBuilder(`/knowledgeitemstatuses/${statusId}/unarchive`),
    {
      method: "POST",
    }
  ).then((res) => res.json());
}

export const fetchIncidents = async (): Promise<Incident[]> => {
  return fetchWithAuth(urlBuilder(`/incidents`), {
    method: "GET",
  }).then((res) => res.json());
};

export const fetchIncident = async (incidentId: string): Promise<Incident> => {
  const response = await fetchWithAuth(urlBuilder(`incidents/${incidentId}`));
  return response.json();
};
