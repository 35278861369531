import { StateCreator } from "zustand";
import { Store } from "./types";
import {
  fetchSessionList as fetchSessionListAPI,
  deleteSessionById as deleteSessionByIdAPI,
  getSessionById as getSessionByIdAPI,
} from "@/lib/api/session";

export const createHistorySlice: StateCreator<
  Store,
  [],
  [],
  Pick<
    Store,
    "history" | "fetchSessionList" | "deleteHistoryItem" | "loadChatHistory"
  >
> = (set, get) => ({
  history: [],
  fetchSessionList: async () => {
    console.log("Fetching chat history");
    const history = await fetchSessionListAPI();
    set({ history });
  },
  deleteHistoryItem: async (historyId: string) => {
    console.log("Deleting chat history with ID: ", historyId);
    await deleteSessionByIdAPI(historyId);
    get().fetchSessionList();
  },
  loadChatHistory: async (historyId: string) => {
    console.log("Loading chat history with ID: ", historyId);
    const response = await getSessionByIdAPI(historyId);
    get().clearChat();
    set({ sessionId: historyId });
    response
      .map((sessionItem) => ({
        ...sessionItem,
        created_at: new Date(sessionItem.created_at),
        updated_at: new Date(sessionItem.updated_at),
      }))
      .sort((a, b) => a.created_at.getTime() - b.created_at.getTime())
      .forEach((sessionItem) => {
        set((state) => {
          console.log({ sessionItem });
          return {
            messages: [
              ...state.messages,
              {
                created_at: sessionItem.created_at.toISOString(),
                id: sessionItem.id,
                role: sessionItem.role,
                feedback: sessionItem.feedback,
                content: {
                  text: sessionItem.content.text,
                  citations: sessionItem.citations,
                },
              },
            ],
          }
        });
      });
  },
});
