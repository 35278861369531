import { create } from "zustand";
import { Store } from "./types";
import { createPackageSlice } from "./packageState";
import { createConfigSlice } from "./configState";
import { createHistorySlice } from "./historyState";
import { createChatSlice } from "./chatState";
import { createFeedbackSlice } from "./feedbackState";
import { createCitationSlice } from "./citationState";
import { createSearchSlice } from "./searchState";
import { createDocumentSlice } from "./documentState";
import { createTopdeskItemSlice } from "./topdeskState";
import { createKnowledgeItemSlice } from "./knowlegeItemState";

export const useStore = create<Store>()((...a) => ({
  ...createPackageSlice(...a),
  ...createConfigSlice(...a),
  ...createHistorySlice(...a),
  ...createChatSlice(...a),
  ...createFeedbackSlice(...a),
  ...createCitationSlice(...a),
  ...createSearchSlice(...a),
  ...createDocumentSlice(...a),
  ...createTopdeskItemSlice(...a),
  ...createKnowledgeItemSlice(...a),
}));
