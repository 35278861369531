import { useEffect, useRef } from "react";
import { CornerDownLeft } from "lucide-react";
import { Button } from "@/components/ui/button";
import { Textarea } from "@/components/ui/textarea";
import { Label } from "@/components/ui/label";
import Message from "@/components/Message";
import { useStore } from "@/state/useState";
import { buildMessage } from "@/lib/api/utils";
import {
  Sheet,
  SheetContent,
  SheetHeader,
  SheetTitle,
  SheetDescription,
} from "@/components/ui/sheet";

export default function ChatInterface() {
  const messages = useStore((state) => state.messages);
  const submitMessage = useStore((state) => state.submitMessage);
  const chatRef = useRef<HTMLDivElement>(null);
  const citation = useStore((state) => state.citation);
  const clearCitation = useStore((state) => state.clearCitation);
  const formRef = useRef<HTMLFormElement>(null);

  // On change of messages, scroll to the bottom
  useEffect(() => {
    // If citation is changed, open the sheet
    chatRef.current?.scrollTo({
      top: chatRef.current.scrollHeight,
      behavior: "smooth",
    });
  }, [messages]);

  const handleSheetOpenChange = (open: boolean) => {
    if (!open) {
      clearCitation();
    }
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    console.log("submitting form");
    e.preventDefault();

    const formData = new FormData(e.currentTarget);
    const messageText = formData.get("message") as string;
    if (!messageText) return;

    e.currentTarget.reset();
    const message = buildMessage({
      message: messageText,
      role: "user",
    });

    submitMessage(message);
    return false;
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      formRef.current?.requestSubmit();
    }
  };

  return (
    <div className="flex flex-col h-full bg-muted/50 rounded-xl border shadow-sm overflow-hidden lg:col-span-2">
      <div
        ref={chatRef}
        className="flex-1 overflow-y-auto p-4 space-y-4 overflow-x-hidden"
      >
        {messages.map((message) => (
          <Message key={message.id} {...message} />
        ))}
      </div>
      <div className="p-4 bg-background border-t">
        <form ref={formRef} onSubmit={handleSubmit} className="space-y-2">
          <div className="relative">
            <Label htmlFor="message" className="sr-only">
              Message
            </Label>
            <Textarea
              id="message"
              name="message"
              placeholder="Type your message here..."
              className="w-full resize-none border rounded-md p-3 focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              rows={3}
              onKeyDown={handleKeyDown}
            />
          </div>
          <div className="flex justify-end">
            <Button type="submit" className="gap-1.5">
              Send Message
              <CornerDownLeft className="size-3.5" />
            </Button>
          </div>
        </form>
      </div>

      <Sheet onOpenChange={handleSheetOpenChange} open={!!citation}>
        <SheetContent>
          <SheetHeader>
            <SheetTitle>{citation?.title}</SheetTitle>
            <SheetDescription>{citation?.content}</SheetDescription>
          </SheetHeader>
        </SheetContent>
      </Sheet>
    </div>
  );
}
