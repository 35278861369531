import { StateCreator } from "zustand";
import { Store } from "./types";
import { getCitation as getCitationAPI } from "@/lib/api/citation";

export const createCitationSlice: StateCreator<
  Store,
  [],
  [],
  Pick<Store, "citation" | "getCitation" | "clearCitation">
> = (set) => ({
  citation: null,
  getCitation: async (document_id, chunk_number) => {
    console.log("Getting chunk number ", chunk_number, " with document ID: ", document_id);

    const citation = await getCitationAPI(document_id, chunk_number);

    console.log("Got citation: ", { citation });
    set({ citation });
  },
  clearCitation: () => {
    console.log("Clearing citation");
    set({ citation: null });
  },
});
