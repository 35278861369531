import { Configuration, PublicClientApplication } from "@azure/msal-browser";

export const APPREG_SCOPE = import.meta.env.VITE_APPREG_SCOPE;

export const configuration: Configuration = {
  auth: {
    clientId: import.meta.env.VITE_APPREG_CLIENT_ID,
    authority: import.meta.env.VITE_APPREG_AUTHORITY,
    redirectUri: import.meta.env.VITE_REDIRECT_URL,
  },
};

export const msalInstance = new PublicClientApplication(configuration);

export const acquireAccessToken = async (
  msalInstance: PublicClientApplication
) => {
  const activeAccount = msalInstance.getActiveAccount();
  const accounts = msalInstance.getAllAccounts();

  if (!activeAccount && accounts.length === 0) {
    throw new Error("User is not signed in");
  }

  const authResult = await msalInstance.acquireTokenSilent({
    scopes: [APPREG_SCOPE],
    account: activeAccount || accounts[0],
  });
  return authResult.accessToken;
};
