import AppLayout from "@/components/AppLayout";
import ChatInterface from "@/components/ChatInterface";
import ChatHistory from "@/components/ChatHistory";

export default function ChatPage() {
  return (
    <AppLayout>
      <ChatHistory />
      <ChatInterface />
    </AppLayout>
  );
}
