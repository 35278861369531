import { StateCreator } from "zustand";
import { Store } from "./types";
import {
  CreateKnowledgeItemParams,
  UpdateKnowledgeItemParams,
  KnowledgeItemTranslation,
} from "@/lib/api/types";
import {
  fetchKnowledgeItems as fetchKnowledgeItemsAPI,
  fetchKnowledgeItem as fetchKnowledgeItemAPI,
  createKnowledgeItem as createKnowledgeItemAPI,
  updateKnowledgeItem as updateKnowledgeItemAPI,
  archiveKnowledgeItem as archiveKnowledgeItemAPI,
  unarchiveKnowledgeItem as unarchiveKnowledgeItemAPI,
  createTranslation as createTranslationAPI,
  updateTranslation as updateTranslationAPI,
  deleteTranslation as deleteTranslationAPI,
  getLinkedBranches as getLinkedBranchesAPI,
  linkBranch as linkBranchAPI,
  unlinkBranch as unlinkBranchAPI,
  listAttachments as listAttachmentsAPI,
  uploadAttachment as uploadAttachmentAPI,
  deleteAttachment as deleteAttachmentAPI,
  getKnowledgeItemStatuses as getKnowledgeItemStatusesAPI,
  fetchIncidents as fetchIncidentsAPI,
  fetchIncident as fetchIncidentAPI,
} from "@/lib/api/topdesk";

export const createTopdeskItemSlice: StateCreator<
  Store,
  [],
  [],
  Pick<
    Store,
    | "knowledgeItems"
    | "currentKnowledgeItem"
    | "linkedBranches"
    | "attachments"
    | "knowledgeItemStatuses"
    | "fetchKnowledgeItems"
    | "fetchKnowledgeItem"
    | "createKnowledgeItem"
    | "updateKnowledgeItem"
    | "archiveKnowledgeItem"
    | "unarchiveKnowledgeItem"
    | "createTranslation"
    | "updateTranslation"
    | "deleteTranslation"
    | "getLinkedBranches"
    | "linkBranch"
    | "unlinkBranch"
    | "listAttachments"
    | "uploadAttachment"
    | "deleteAttachment"
    | "getKnowledgeItemStatuses"
    | "incidents"
    | "currentIncident"
    | "fetchIncidents"
    | "fetchIncident"
  >
> = (set, get) => ({
  knowledgeItems: [],
  currentKnowledgeItem: null,
  linkedBranches: [],
  attachments: [],
  knowledgeItemStatuses: [],

  fetchKnowledgeItems: async (
    start: number = 0,
    pageSize: number = 100,
    fields?: string,
    query?: string,
    language?: string
  ) => {
    try {
      const knowledgeItems = await fetchKnowledgeItemsAPI(
        start,
        pageSize,
        fields,
        query,
        language
      );
      if (knowledgeItems.next) {
        // If there are more items to fetch, do so, and append them to the existing list
        await get().fetchKnowledgeItems(
          start + pageSize,
          pageSize,
          fields,
          query,
          language
        );
      }
      set((state) => ({
        knowledgeItems: [
          ...state.knowledgeItems,
          ...knowledgeItems.item,
        ].filter(
          (item, index, self) =>
            self.findIndex((i) => i.id === item.id) === index
        ),
      }));

      console.log("knowledgeItems", knowledgeItems);
    } catch (error) {
      console.error("Error fetching knowledge items:", error);
    }
  },

  fetchKnowledgeItem: async (
    knowledgeItemId: string,
    fields?: string,
    language?: string
  ) => {
    try {
      const knowledgeItem = await fetchKnowledgeItemAPI(
        knowledgeItemId,
        fields,
        language
      );
      set({ currentKnowledgeItem: knowledgeItem });
    } catch (error) {
      console.error("Error fetching knowledge item:", error);
    }
  },

  createKnowledgeItem: async (knowledgeItemData: CreateKnowledgeItemParams) => {
    try {
      const createdKnowledgeItem = await createKnowledgeItemAPI(
        knowledgeItemData
      );
      set((state) => ({
        knowledgeItems: [...state.knowledgeItems, createdKnowledgeItem],
        currentKnowledgeItem: createdKnowledgeItem,
      }));
    } catch (error) {
      console.error("Error creating knowledge item:", error);
    }
  },

  updateKnowledgeItem: async (
    knowledgeItemId: string,
    knowledgeItemData: UpdateKnowledgeItemParams
  ) => {
    try {
      await updateKnowledgeItemAPI(knowledgeItemId, knowledgeItemData);
      set((state) => ({
        knowledgeItems: state.knowledgeItems.map((item) =>
          item.id === knowledgeItemId ? { ...item, ...knowledgeItemData } : item
        ),
        currentKnowledgeItem:
          state.currentKnowledgeItem &&
          state.currentKnowledgeItem.id === knowledgeItemId
            ? { ...state.currentKnowledgeItem, ...knowledgeItemData }
            : state.currentKnowledgeItem,
      }));
    } catch (error) {
      console.error("Error updating knowledge item:", error);
    }
  },

  archiveKnowledgeItem: async (knowledgeItemId: string) => {
    try {
      await archiveKnowledgeItemAPI(knowledgeItemId);
      set((state) => ({
        knowledgeItems: state.knowledgeItems.filter(
          (item) => item.id !== knowledgeItemId
        ),
        currentKnowledgeItem:
          state.currentKnowledgeItem &&
          state.currentKnowledgeItem.id === knowledgeItemId
            ? null
            : state.currentKnowledgeItem,
      }));
    } catch (error) {
      console.error("Error archiving knowledge item:", error);
    }
  },

  unarchiveKnowledgeItem: async (knowledgeItemId: string) => {
    try {
      await unarchiveKnowledgeItemAPI(knowledgeItemId);
      // After unarchiving, you might want to fetch the item again to get its updated state
      await get().fetchKnowledgeItem(knowledgeItemId);
    } catch (error) {
      console.error("Error unarchiving knowledge item:", error);
    }
  },

  createTranslation: async (
    knowledgeItemId: string,
    translation: KnowledgeItemTranslation
  ) => {
    try {
      await createTranslationAPI(knowledgeItemId, translation);
      // After creating a translation, you might want to fetch the item again to get its updated state
      await get().fetchKnowledgeItem(knowledgeItemId);
    } catch (error) {
      console.error("Error creating translation:", error);
    }
  },

  updateTranslation: async (
    knowledgeItemId: string,
    language: string,
    content: KnowledgeItemTranslation["content"]
  ) => {
    try {
      await updateTranslationAPI(knowledgeItemId, language, content);
      // After updating a translation, you might want to fetch the item again to get its updated state
      await get().fetchKnowledgeItem(knowledgeItemId);
    } catch (error) {
      console.error("Error updating translation:", error);
    }
  },

  deleteTranslation: async (knowledgeItemId: string, language: string) => {
    try {
      await deleteTranslationAPI(knowledgeItemId, language);
      // After deleting a translation, you might want to fetch the item again to get its updated state
      await get().fetchKnowledgeItem(knowledgeItemId);
    } catch (error) {
      console.error("Error deleting translation:", error);
    }
  },

  getLinkedBranches: async (knowledgeItemId: string) => {
    try {
      const branches = await getLinkedBranchesAPI(knowledgeItemId);
      set({ linkedBranches: branches });
    } catch (error) {
      console.error("Error getting linked branches:", error);
    }
  },

  linkBranch: async (knowledgeItemId: string, branchId: string) => {
    try {
      await linkBranchAPI(knowledgeItemId, branchId);
      await get().getLinkedBranches(knowledgeItemId);
    } catch (error) {
      console.error("Error linking branch:", error);
    }
  },

  unlinkBranch: async (knowledgeItemId: string, branchId: string) => {
    try {
      await unlinkBranchAPI(knowledgeItemId, branchId);
      await get().getLinkedBranches(knowledgeItemId);
    } catch (error) {
      console.error("Error unlinking branch:", error);
    }
  },

  listAttachments: async (knowledgeItemId: string) => {
    try {
      const attachments = await listAttachmentsAPI(knowledgeItemId);
      set({ attachments });
    } catch (error) {
      console.error("Error listing attachments:", error);
    }
  },

  uploadAttachment: async (
    knowledgeItemId: string,
    file: File,
    description?: string
  ) => {
    try {
      await uploadAttachmentAPI(knowledgeItemId, file, description);
      await get().listAttachments(knowledgeItemId);
    } catch (error) {
      console.error("Error uploading attachment:", error);
    }
  },

  deleteAttachment: async (knowledgeItemId: string, attachmentId: string) => {
    try {
      await deleteAttachmentAPI(knowledgeItemId, attachmentId);
      await get().listAttachments(knowledgeItemId);
    } catch (error) {
      console.error("Error deleting attachment:", error);
    }
  },

  getKnowledgeItemStatuses: async (archived?: boolean) => {
    try {
      const statuses = await getKnowledgeItemStatusesAPI(archived);
      set({ knowledgeItemStatuses: statuses });
    } catch (error) {
      console.error("Error getting knowledge item statuses:", error);
    }
  },

  incidents: [],
  currentIncident: null,
  fetchIncidents: async () => {
    const incidents = await fetchIncidentsAPI();
    set({ incidents });
  },
  fetchIncident: async (incidentId: string) => {
    const incident = await fetchIncidentAPI(incidentId);
    set({ currentIncident: incident });
  },
});
