import {
  useReactTable,
  getCoreRowModel,
  flexRender,
  ColumnDef,
} from "@tanstack/react-table";
import { CheckCircle, Settings, Trash2, Download } from "lucide-react";
import { Button } from "@/components/ui/button";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { useStore } from "@/state/useState";
import { useEffect } from "react";
import { fetchWithAuth, urlBuilder } from "@/lib/api/utils";
import type { Document } from "@/lib/api/types";
import { formatFileSize } from "@/lib/utils";

export default function FileStatsTable() {
  const documents = useStore((state) => state.documents);
  const loadDocuments = useStore((state) => state.loadDocuments);

  useEffect(() => {
    loadDocuments();
  }, [loadDocuments]);

  const handleDownload = async (documentId: string) => {
    console.log("Download", documentId);
    try {
      const response = await fetchWithAuth(
        urlBuilder(`/doc/download/${documentId}`)
      );
      if (!response.ok) {
        throw new Error("Download failed");
      }

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;

      // Get the document from our current documents state
      const documentData = documents.find((doc) => doc.id === documentId);
      const filename = documentData
        ? documentData.origin
        : `document-${documentId}`;

      a.download = filename;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    } catch (error) {
      console.error("Error downloading document:", error);
      alert("Error downloading document.");
    }
  };

  const handleDelete = (documentId: string) => {
    fetchWithAuth(urlBuilder(`/document/${documentId}`), {
      method: "DELETE",
    })
      .then((response) => {
        if (response.ok) {
          loadDocuments();
        } else {
          alert("Error deleting document.");
        }
      })
      .catch((error) => {
        console.error("Error deleting document:", error);
        alert("Error deleting document.");
      });
  };

  const columns: ColumnDef<Document>[] = [
    {
      accessorKey: "origin",
      header: "Name",
    },
    {
      accessorKey: "data.chunkCount",
      header: "Chunks",
    },
    {
      accessorKey: "data.contentLength",
      header: "Size",
      cell: ({ getValue }) => formatFileSize(getValue() as number),
    },
    {
      accessorKey: "status",
      header: "Status",
      cell: ({ getValue }) =>
        // this status refers to the DocumentStatus (0 for pending, 1 for processing, and 2 for processed)
        getValue() === 2 ? (
          <CheckCircle className="text-green-500" />
        ) : (
          <Settings className="text-yellow-500" />
        ),
    },
    {
      id: "actions",
      header: "Actions",
      cell: ({ row }) => (
        <>
          <Button
            variant="ghost"
            size="sm"
            onClick={() => handleDownload(row.original.id)}
          >
            <Download className="h-4 w-4" />
          </Button>
          <Button
            variant="ghost"
            size="sm"
            onClick={() => handleDelete(row.original.id)}
          >
            <Trash2 className="h-4 w-4 text-red-500" />
          </Button>
        </>
      ),
    },
  ];

  const table = useReactTable({
    data: documents,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <Table>
      <TableHeader>
        {table.getHeaderGroups().map((headerGroup) => (
          <TableRow key={headerGroup.id}>
            {headerGroup.headers.map((header) => (
              <TableHead key={header.id}>
                {flexRender(
                  header.column.columnDef.header,
                  header.getContext()
                )}
              </TableHead>
            ))}
          </TableRow>
        ))}
      </TableHeader>
      <TableBody>
        {table.getRowModel().rows.map((row) => (
          <TableRow key={row.id}>
            {row.getVisibleCells().map((cell) => (
              <TableCell key={cell.id}>
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </TableCell>
            ))}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}
