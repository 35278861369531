import React from "react";
import { useStore } from "@/state/useState";
import {
  Card,
  CardHeader,
  CardTitle,
  CardDescription,
  CardContent,
} from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";

export default function SearchForm() {
  const config = useStore((state) => state.config);
  const search = useStore((state) => state.search);
  const selectedDocuments = useStore((state) => state.selectedDocuments);

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const formData = new FormData(event.currentTarget);
    const query = formData.get("query") as string;

    const selectedDocumentIds = selectedDocuments.map((doc) => doc.id);

    search(query, selectedDocumentIds);
  };

  return (
    <Card className="w-full">
      <CardHeader>
        <CardTitle className="text-3xl text-center">
          {config?.search.title}
        </CardTitle>
        <CardDescription className="text-center">
          {config?.search.description}
        </CardDescription>
      </CardHeader>
      <CardContent>
        <form onSubmit={onSubmit} className="space-y-4">
          <Input
            type="text"
            name="query"
            placeholder={config?.search.placeholder}
            autoFocus
          />
          <p className="text-xs text-gray-400 pl-2">{config?.search.hint}</p>
          <Button type="submit" className="w-full">
            {config?.search.button_text}
          </Button>
        </form>
      </CardContent>
    </Card>
  );
}
