import { fetchWithAuth, urlBuilder } from "@/lib/api/utils";
import { Document } from "@/lib/api/types";

export async function fetchDocuments(): Promise<Document[]> {
  return fetchWithAuth(urlBuilder("/document")).then((res) => res.json());
}

export async function getDocument(documentId: string): Promise<Document> {
  return fetchWithAuth(urlBuilder(`/document/${documentId}`)).then((res) =>
    res.json()
  );
}
