import { StateCreator } from "zustand";
import { Store } from "./types";
import {
  categorize as categorizeAPI,
  assignOperator as assignOperatorAPI,
} from "@/lib/api/knowledgeItem";

export const createKnowledgeItemSlice: StateCreator<
  Store,
  [],
  [],
  Pick<
    Store,
    | "category"
    | "categorizeText"
    | "clearCategory"
    | "operator"
    | "assignOperator"
    | "clearOperator"
  >
> = (set) => ({
  category: {
    category: "",
    subcategory: "",
  },
  categorizeText: async (text: string) => {
    console.log("Getting category for text: ", text);
    const category = await categorizeAPI(text);
    set({ category });
  },
  clearCategory: () => {
    console.log("Clearing category");
    set({
      category: {
        category: "",
        subcategory: "",
      },
    });
  },
  operator: null,
  assignOperator: async (text: string) => {
    console.log("Assigning operator for text: ", text);
    const response = await assignOperatorAPI(text);
    console.log("Operator assigned: ", response);
    set({ operator: response.operator });
  },
  clearOperator: () => {
    console.log("Clearing operator");
    set({
      operator: null,
    });
  },
});
