import { StateCreator } from "zustand";
import { Store } from "./types";
import { submitFeedback as submitFeedbackAPI } from "@/lib/api/feedback";

export const createFeedbackSlice: StateCreator<
  Store,
  [],
  [],
  Pick<Store, "submitFeedback">
> = (set, get) => ({
  submitFeedback: async (messageId: string, feedback: string) => {
    console.log("Submitting feedback for message ID: ", messageId);
    await submitFeedbackAPI(messageId, feedback);
    set((state) => ({
      messages: state.messages.map((message) =>
        message.id === messageId ? { ...message, feedback } : message
      ),
    }));
    get().fetchSessionList();
  },
});
